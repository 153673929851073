
import { Options, Vue } from "vue-property-decorator";
import GuestLayout from "@/layout/GuestLayout.vue";

@Options({
  components: {
    GuestLayout,
  },
})
export default class RegistrationStart extends Vue {
  public name = "RegistrationStart";

  private next() {
    this.$router.push({ name: "QrCodePage" });
  }
}
